<template>
  <div>
    <v-btn
      v-if="!drawer"
      class="list-btn"
      color="secondary"
      @click="drawer = !drawer"
    >
      <v-icon>format_list_bulleted</v-icon>リスト
    </v-btn>
    <v-navigation-drawer
      v-model="drawer"
      app
      :permanent="$vuetify.breakpoint.mdAndUp"
    >
      <v-subheader>
        <v-list-item-action>
          <v-icon color="red">favorite</v-icon>
        </v-list-item-action>
        <v-list-item-action-text> お気に入りリスト </v-list-item-action-text>
      </v-subheader>
      <v-divider></v-divider>
      <v-list>
        <FavChargerListItem
          v-for="chargerInfo in favoriteList"
          :key="chargerInfo.id"
          :selectedId="selectedId"
          :chargerInfo="chargerInfo"
          @click="selected"
        />
        <v-list-item class="blank"></v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import FavChargerListItem from "@/components/FavChargerListItem.vue";
import { mapState } from "vuex";
import { EventBus } from "@/event.js";

export default {
  name: "Favorite",
  components: { FavChargerListItem },
  data() {
    return {
      drawer: false,
      selectedId: null,
    };
  },
  computed: {
    ...mapState(["favedChargers"]),
    favoriteList() {
      return Object.keys(this.favedChargers).map(
        (id) => this.favedChargers[id]
      );
    },
  },
  methods: {
    selected(id) {
      this.selectedId = id;
    },
  },
  beforeDestroy: function () {
    this.$store.commit("start", null);
    this.$store.commit("destination", null);
    this.$store.commit("routeLegs", []);
    this.$store.commit("chargers", []);
    this.$store.commit("polylines", []);
  },
  mounted() {
    this.$store.commit(
      "chargers",
      Object.keys(this.favedChargers).map((id) => this.favedChargers[id])
    );
    EventBus.$emit("bounding");
  },
  metaInfo: {
    title: "お気に入り",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "お気に入りに保存した電気自動車用充電器を確認できます。",
      },
    ],
  },
};
</script>


<style scoped>
.base {
  transition-duration: 0.15s;
  position: absolute;
  bottom: 0;
  transition-property: all;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  max-height: 100%;
}
.selected {
  height: auto;
  overflow: hidden;
}

.list-btn {
  position: absolute;
  top: 15px;
  left: 15px;
}

.blank {
  height: 400px;
}
</style>