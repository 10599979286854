<template>
  <div>
    <v-list-item
      @click="clickItem"
      :class="{ active: selectedId == chargerInfo.id }"
    >
      <v-container fluid>
        <v-row>
          <v-avatar style="width: ">
            <v-img
              contain
              height="30px"
              v-if="
                chargerInfo.rapidChargerFlag && chargerInfo.normalChargerFlag
              "
              src="/img/charger_icon/charge_icon_set_01.png"
            ></v-img>
            <v-img
              contain
              height="30px"
              v-else-if="chargerInfo.rapidChargerFlag"
              src="/img/charger_icon/charge_icon_fast_01.png"
            ></v-img>
            <v-img
              contain
              height="30px"
              v-else
              src="/img/charger_icon/charge_icon_normal_01.png"
            ></v-img>
          </v-avatar>
        </v-row>
        <v-row>
          <v-col cols="10" class="body-1 pa-0 pb-1 my-2">{{
            chargerInfo.name
          }}</v-col>
          <v-col cols="2" class="pa-0">
            <v-btn icon @click="switchFav">
              <v-icon v-if="faved" color="red">mdi-heart</v-icon>
              <v-icon v-else color="gray">mdi-heart-plus-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <div
          v-if="
            selectedId == chargerInfo.id && chargerInfo.numberOfRapidCharger
          "
        >
          <div class="caption font-weight-bold">急速充電器</div>
          <div class="body-2 ml-3">
            {{ chargerInfo.numberOfRapidCharger }}台
          </div>
        </div>
        <div
          v-if="
            selectedId == chargerInfo.id && chargerInfo.numberOfRapidCharger
          "
        >
          <div class="caption font-weight-bold">急速充電器営業時間</div>
          <div class="body-2 ml-3">
            {{
              chargerInfo.RapidChargerBusinessTime
                ? chargerInfo.RapidChargerBusinessTime
                : "不明"
            }}
          </div>
        </div>
        <div
          v-if="
            selectedId == chargerInfo.id &&
            (chargerInfo.numberOf200vCharger || chargerInfo.numberOf100vCharger)
          "
        >
          <div class="caption font-weight-bold">普通充電器</div>
          <div class="body-2 ml-3">
            {{
              chargerInfo.numberOf100vCharger + chargerInfo.numberOf200vCharger
            }}台
          </div>
        </div>
        <div
          v-if="
            selectedId == chargerInfo.id &&
            (chargerInfo.numberOf200vCharger || chargerInfo.numberOf100vCharger)
          "
        >
          <div class="caption font-weight-bold">普通充電器営業時間</div>
          <div class="body-2 ml-3">
            {{
              chargerInfo.NormalChargerBusinessTime
                ? chargerInfo.NormalChargerBusinessTime
                : "不明"
            }}
          </div>
        </div>
        <div v-if="selectedId == chargerInfo.id">
          <div class="caption font-weight-bold">休業日</div>
          <div class="body-2 ml-3">
            {{
              chargerInfo.NormalChargerHoliday
                ? chargerInfo.NormalChargerHoliday
                : chargerInfo.RapidChargerHoliday
                ? chargerInfo.RapidChargerHoliday
                : "不明"
            }}
          </div>
        </div>
        <div v-if="selectedId == chargerInfo.id" class="caption">
          <ExtarnalLink
            :url="`https://www.google.com/maps/search/?api=1&query=${chargerInfo.location.lat},${chargerInfo.location.lon}`"
            linkText="Googleマップで開く"
            :linkEventLabel="`${this.chargerInfo.id} in google map`"
          />
        </div>
        <div v-if="selectedId == chargerInfo.id && isIOS" class="caption">
          <ExtarnalLink
            :url="`https://maps.apple.com/?q=${chargerInfo.location.lat},${chargerInfo.location.lon}`"
            linkText="マップアプリで開く"
            :linkEventLabel="`${this.chargerInfo.id} in apple map`"
          />
        </div>
      </v-container>
    </v-list-item>
    <v-divider></v-divider>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import { hubeny } from "@/lib/hubeny.js";
import { EventBus } from "@/event.js";
import ExtarnalLink from "@/components/ExtarnalLink.vue";

export default {
  components: { ExtarnalLink },
  data() {
    return {
      isIOS: false,
    };
  },
  props: ["selectedId", "chargerInfo"],
  computed: {
    ...mapState(["favedChargers"]),
    faved: function () {
      if (this.chargerInfo) {
        return this.chargerInfo.id in this.favedChargers;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.isIOS = /iP(hone|(o|a)d)/.test(navigator.userAgent);
  },
  methods: {
    switchFav() {
      if (this.faved) {
        this.$gtag.event("faved", {
          event_category: "addFavorite",
          event_label: String(this.chargerInfo.id),
        });
        this.$store.commit("DeleteFavedCharger", this.chargerInfo);
      } else if (Object.keys(this.favedChargers).length >= 10) {
        this.$gtag.event("faved", {
          event_category: "denied",
        });
        EventBus.$emit("error", "お気に入り登録できるのは10か所までです");
      } else {
        this.$gtag.event("faved", {
          event_category: "removeFavorite",
          event_label: String(this.chargerInfo.id),
        });
        this.$store.commit("AddFavedCharger", this.chargerInfo);
      }
    },
    clickItem() {
      this.$gtag.event("click", {
        event_category: "clickFavorite",
        event_label: String(this.chargerInfo.id),
      });
      EventBus.$emit("centering", {
        lat: this.chargerInfo.location.lat,
        lng: this.chargerInfo.location.lon,
      });
      this.$emit("click", this.chargerInfo.id);
      EventBus.$emit("clickFavorite", this.chargerInfo.id);
    },
  },
};
</script>

<style scoped>
.z-over {
  z-index: 10;
}

.active {
  background-color: rgba(255, 255, 0, 0.2);
}
</style>